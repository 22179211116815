import React from 'react';
import { Button, Grid } from '@mui/material';
import { ChillerLog } from '../../model';
import CompressorDetailsSubsection from './CompressorDetailsSubSection';

import { ChillerCircuit } from '../../model';

interface CompressorDetailsSectionProps {
  chillerLog: ChillerLog;
}

const rowLabels = [
  '',
  'Submit Date',
  'Discharge Temp',
  'Discharge PSI',
  'Discharge Superheat',
  'Oil Discharge Pressure',
  'Oil PSI Net',
  'Oil Supply Temp',
  'Oil Filter Pressure Drop',
  '%RLA',
  'Slide Valve Position',
  'Starts',
  'Hours',
  'Motor Temp (Highest)',
  'Amps L1',
  'Amps L2',
  'Amps L3',
  'Volts AB',
  'Volts AC',
  'Volts BC',
];

const CompressorDetailsSection: React.FunctionComponent<CompressorDetailsSectionProps> = ({ chillerLog }) => {
  const [circuits, setCircuits] = React.useState(chillerLog?.circuits || []);

  React.useEffect(() => {
    if (chillerLog) setCircuits(chillerLog.circuits);
  }, [chillerLog]);

  const addCompressor = (circuit: ChillerCircuit) => {
    let newCount = circuit.compressor_count + 1;

    setCircuits((prevCircuits) =>
      prevCircuits.map((c, index) => (c.id === circuit.id ? { ...c, compressor_count: newCount } : c)),
    );

    const storedData = localStorage.getItem('chillerCircuitSavedValues');
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};

    parsedStoredData[circuit.id].compressor_count = newCount;

    localStorage.setItem('chillerCircuitSavedValues', JSON.stringify(parsedStoredData));
  };

  return (
    <>
      {circuits.map((circuit, circuitIndex) => (
        <Grid container style={{ width: '100%' }} key={circuitIndex}>
          <Grid item xs={12}>
            <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
              Compressor Details (Circuit {circuitIndex + 1})
            </h3>
          </Grid>
          <Grid item xs={1} sx={{ minWidth: 200 }}>
            <Grid container>
              {rowLabels.map((label) => (
                <Grid
                  item
                  xs={12}
                  key={label}
                  sx={{
                    minHeight: 33,
                    borderTop: '1px solid #ddd',
                    borderRight: '2px solid black',
                    background: '#F5F5F5',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <p style={{ color: '#000' }}>{label || '\u00A0'}</p>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={10} sx={{ minWidth: 250 }}>
            <Grid container>
              {circuit.compressors?.slice(0, circuit?.compressor_count)?.map((compressor, index) => (
                <Grid item xs={3} key={compressor.id} sx={{ minWidth: 250 }}>
                  <CompressorDetailsSubsection compressor={compressor} />
                </Grid>
              ))}
              {circuit.compressors.length <= 3 && (
                <Grid item xs={3} sx={{ minWidth: 250 }} className="no-print">
                  <Button onClick={() => addCompressor(circuit)}>Add Compressor</Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default CompressorDetailsSection;
