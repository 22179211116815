import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertBubbleIcon } from '@shopify/polaris-icons';
import { Button, Grid, Typography, Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import { Navigation } from '@shopify/polaris';
import { AuthenticationContext } from '../../../homeModules/auth/contexts/AuthenticationContext';

const options = ['Sales Estimates', 'Chiller Logs', 'Project Management', 'Warehouse'];

const ChillerLogSidebar: React.FC = () => {
  const location = useLocation();

  const { user } = React.useContext(AuthenticationContext);

  const isTechnicianUser = user?.modules?.some(
    (module) => module.value === 'chiller_logs' && parseInt(module.tier) <= 2,
  );
  const isAdminUser = user?.modules?.some((module) => module.value === 'chiller_logs' && parseInt(module.tier) > 2);

  const [rebatesToReviewCount, setRebatesToReviewCount] = useState<number>(0);
  const [showSalesModule, setShowSalesModule] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const reviewRebatesLabel = `Rebates to Review ${rebatesToReviewCount > 0 ? `(${rebatesToReviewCount})` : ''}`;

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setShowSalesModule(index === 0);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          ...(isTechnicianUser
            ? [
                {
                  label: 'Technician Chiller Logs',
                  url: '/chiller_logs',
                  icon: AlertBubbleIcon,
                  matches: true,
                  subNavigationItems: [
                    {
                      url: '/chiller_logs/technician',
                      disabled: false,
                      label: 'Jobs',
                    },
                  ],
                },
              ]
            : []),
          ...(isAdminUser
            ? [
                {
                  label: 'Admin Chiller Logs',
                  url: '/chiller_logs',
                  icon: AlertBubbleIcon,
                  matches: true,
                  subNavigationItems: [
                    {
                      url: '/chiller_logs/admin',
                      disabled: false,
                      label: 'Jobs',
                    },
                    {
                      url: '/chiller_logs/approvals',
                      disabled: false,
                      label: 'Approval Queue',
                    },
                  ],
                },
                {
                  label: 'Module Settings',
                  url: '/chiller_logs/settings',
                  icon: AlertBubbleIcon,
                  matches: true,
                  subNavigationItems: [
                    {
                      url: '/chiller_logs/settings',
                      disabled: false,
                      label: 'Field Validations',
                    },
                  ],
                },
              ]
            : []),
        ]}
      />
    </Navigation>
  );
};

export default ChillerLogSidebar;
