import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChillerLogHeader from '../components/ChillerLogHeader';
import InformationField from '../../../modules/form/components/InformationField';

const sampleLastSubmissionData = [
  { field: 'Suction Line PSI', circuit1: '45', circuit2: '46', circuit3: '47', circuit4: '48' },
  { field: 'Saturation Temp', circuit1: '42', circuit2: '43', circuit3: '44', circuit4: '45' },
  { field: 'Suction Line Temp', circuit1: '55', circuit2: '56', circuit3: '57', circuit4: '58' },
  { field: 'Suction Superheat', circuit1: '10', circuit2: '11', circuit3: '12', circuit4: '13' },
  { field: 'Expansion Valve Position', circuit1: '22', circuit2: '24', circuit3: '26', circuit4: '28' },
  { field: 'Discharge Temp', circuit1: '140', circuit2: '143', circuit3: '146', circuit4: '149' },
  { field: 'Discharge PSI', circuit1: '220', circuit2: '223', circuit3: '225', circuit4: '230' },
  { field: 'Discharge Superheat', circuit1: '20', circuit2: '22', circuit3: '25', circuit4: '28' },
  { field: 'Oil Discharge Pressure', circuit1: '54', circuit2: '56', circuit3: '58', circuit4: '59' },
  { field: 'Oil PSI Net', circuit1: '40', circuit2: '42', circuit3: '44', circuit4: '45' },
  { field: 'Oil Supply Temp', circuit1: '60', circuit2: '62', circuit3: '65', circuit4: '67' },
  { field: 'Oil Filter Pressure Drop', circuit1: '8', circuit2: '10', circuit3: '12', circuit4: '14' },
  { field: '%RLA', circuit1: '75', circuit2: '80', circuit3: '85', circuit4: '90' },
  { field: 'Slide Valve Position', circuit1: '50', circuit2: '52', circuit3: '55', circuit4: '60' },
  { field: 'Starts', circuit1: '100', circuit2: '110', circuit3: '120', circuit4: '130' },
  { field: 'Hours', circuit1: '1000', circuit2: '1100', circuit3: '1200', circuit4: '1300' },
  { field: 'Motor Temp (Highest)', circuit1: '95', circuit2: '98', circuit3: '100', circuit4: '105' },
  { field: 'Amps L1', circuit1: '80', circuit2: '82', circuit3: '85', circuit4: '90' },
  { field: 'Amps L2', circuit1: '85', circuit2: '87', circuit3: '90', circuit4: '92' },
  { field: 'Amps L3', circuit1: '88', circuit2: '90', circuit3: '92', circuit4: '95' },
  { field: 'Volts AB', circuit1: '220', circuit2: '220', circuit3: '225', circuit4: '230' },
  { field: 'Volts AC', circuit1: '220', circuit2: '220', circuit3: '225', circuit4: '230' },
  { field: 'Volts BC', circuit1: '220', circuit2: '220', circuit3: '225', circuit4: '230' },
];

interface PreviousLogDialogProps {
  dialogOpen: any;
  setDialogOpen: any;
}

const PreviousLogDialog: React.FunctionComponent<PreviousLogDialogProps> = (props) => {
  const { dialogOpen, setDialogOpen } = props;

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Previous Submission Data (07/02/24)</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Circuit 1</TableCell>
              <TableCell>Circuit 2</TableCell>
              <TableCell>Circuit 3</TableCell>
              <TableCell>Circuit 4</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sampleLastSubmissionData.map((item, index) => (
              <>
                {index === 6 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
                        Evaporator Details
                      </h3>
                    </TableCell>
                  </TableRow>
                )}

                {index === 18 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>
                        Condenser Details
                      </h3>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow key={index}>
                  <TableCell>{item.field}</TableCell>
                  <TableCell>{item.circuit1}</TableCell>
                  <TableCell>{item.circuit2}</TableCell>
                  <TableCell>{item.circuit3}</TableCell>
                  <TableCell>{item.circuit4}</TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviousLogDialog;
