import React, { useState, useEffect } from 'react';
import Spreadsheet from 'react-spreadsheet';
import Grid from '@mui/material/Grid';
import { ChillerCompressor } from '../../model';

interface CondenserDetailsSubsectionProps {
  compressor: ChillerCompressor;
}

const CondenserDetailsSubsection: React.FunctionComponent<CondenserDetailsSubsectionProps> = (props) => {
  let { compressor } = props;

  let [compressorSubSectionFormValues, setCompressorSubSectionFormValues] = useState<any>({});

  let readIndex = 0;
  if (compressor?.index !== undefined) {
    readIndex = compressor?.index + 1;
  }

  useEffect(() => {
    setCompressorSubSectionFormValues(compressor?.compressor_details);

    const storedData = localStorage.getItem('chillerCompressorSavedValues');
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};

    if (!parsedStoredData[compressor.id]) {
      parsedStoredData[compressor.id] = compressor?.compressor_details;
      localStorage.setItem('chillerCompressorSavedValues', JSON.stringify(parsedStoredData));
    }
  }, [compressor]);

  const handleSpreadsheetChange = (updatedData) => {
    setCompressorSubSectionFormValues(updatedData);
    const allStoredData = JSON.parse(localStorage.getItem('chillerCompressorSavedValues') || '{}');
    allStoredData[compressor.id] = updatedData;
    localStorage.setItem('chillerCompressorSavedValues', JSON.stringify(allStoredData));
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ minWidth: 250 }}>
        <Spreadsheet
          data={compressor?.historical_data?.compressor_details}
          hideRowIndicators
          columnLabels={[`Compr. ${compressor?.index + 1}`, '']}
          className="hide-labels circuit-table historical-data"
        />
        {compressorSubSectionFormValues?.length > 0 && (
          <Spreadsheet
            data={compressorSubSectionFormValues}
            hideRowIndicators
            columnLabels={['']}
            onChange={handleSpreadsheetChange}
            className="hide-labels circuit-table values-data"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CondenserDetailsSubsection;
