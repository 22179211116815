import React from 'react';
import Grid from '@mui/material/Grid';
import { ChillerLog } from '../../model';
import EvaporatorDetailsSubsection from './EvaporatorDetailsSubSection';

interface EvaporatorDetailsSectionProps {
  chillerLog: ChillerLog;
}

const rowLabels = [
  '',
  'Submit Date',
  'Suction Line PSI',
  'Saturation Temp',
  'Suction Line Temp',
  'Suction Superheat',
  'Approach',
  'Delta',
  'Expansion Valve Position',
];

const EvaporatorDetailsSection: React.FunctionComponent<EvaporatorDetailsSectionProps> = (props) => {
  let { chillerLog } = props;

  return (
    chillerLog && (
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={12}>
          <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Evaporator Details</h3>
        </Grid>
        <Grid item xs={1} sx={{ minWidth: 200 }}>
          <Grid container>
            {rowLabels.map((label) => (
              <Grid
                item
                xs={12}
                key={label}
                sx={{
                  minHeight: 33,
                  borderTop: '1px solid #ddd',
                  borderRight: '2px solid black',
                  background: '#F5F5F5',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ color: '#000' }}>{label || '\u00A0'}</p>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={10} sx={{ minWidth: 250 }}>
          <Grid container>
            {chillerLog?.circuits?.map((circuit, index) => (
              <Grid item xs={3} key={circuit.id} sx={{ minWidth: 250 }}>
                <EvaporatorDetailsSubsection chillerCircuit={circuit} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default EvaporatorDetailsSection;
