import React, { useState, useEffect, useContext } from 'react';
import { Grid, TextField } from '@mui/material';
import FilterSelectInput from '../../../modules/form/components/FilterSelectInput';
import axios from '../../../modules/utils/axios.utils';
import { EstimateDetailsContext } from '../../../modules/estimateDetails/contexts/EstimateDetailsContext';
import { AuthenticationContext } from '../../../homeModules/auth/contexts/AuthenticationContext';

interface ChillerTechnicianJobsFilterProps {
  filterValues: Record<string, string>;
  setFilterValues: React.Dispatch<any>;
}

const formatDate = (date: Date) => date.toISOString().split('T')[0];

const ChillerTechnicianJobsFilter: React.FC<ChillerTechnicianJobsFilterProps> = (props) => {
  const { filterValues, setFilterValues } = props;

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [startDate, setStartDate] = useState<string>(formatDate(today));
  const [endDate, setEndDate] = useState<string>(formatDate(tomorrow));

  const [filterChoices, setFilterChoices] = useState<any>({});

  const { fetchSalesEstimates } = useContext(EstimateDetailsContext);
  const { user } = useContext(AuthenticationContext);

  const fetchChoices = async () => {
    await axios.get<string, any>('sales_estimates/new').then((response) => {
      setFilterChoices(response?.data);
    });
  };

  useEffect(() => {
    if (user?.id) {
      fetchSalesEstimates(user?.id);
    }
    fetchChoices();
  }, []);

  const handleUserSelected = (value: string) => {
    setFilterValues({ ...filterValues, user_id_eq: value });
    fetchSalesEstimates(value);
  };

  const handleDateChange = (date: string, type: 'start' | 'end') => {
    if (type === 'start') {
      setStartDate(date);
      setFilterValues({ ...filterValues, start_date_gteq: date });
    } else {
      setEndDate(date);
      setFilterValues({ ...filterValues, end_date_lteq: date });
    }
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleUserSelected(filterValues?.user_id_eq);
    }
  };

  return (
    <Grid container spacing={1} alignItems="flex-start" onKeyDown={handleFiltersKeyPress}>
      {/* <Grid item xs={12} md={3}>
        <FilterSelectInput
          value={'0'}
          choices={[
            { label: 'All Clients', value: '0' },
            { label: 'Demo Client', value: '' },
          ]}
          valueChanged={(value) => handleUserSelected(value)}
          label="Client"
        />
      </Grid> */}
      <Grid item xs={12} md={3}>
        <TextField
          label="Start Date"
          type="date"
          variant="standard"
          style={{ width: '100%', marginTop: 8 }}
          value={startDate}
          onChange={(e) => handleDateChange(e.target.value, 'start')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="End Date"
          type="date"
          variant="standard"
          style={{ width: '100%', marginTop: 8 }}
          value={endDate}
          onChange={(e) => handleDateChange(e.target.value, 'end')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ChillerTechnicianJobsFilter;
