import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import axios from '../../../modules/utils/axios.utils';
import ChillerJobsFilter from '../components/ChillerJobsFilter';
import ChillerTechnicianJobsFilter from '../components/ChillerTechnicianJobsFilter';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    padding: 0,
  },
  box: {
    width: '100%',
  },
  content: {
    marginLeft: '0px',
    padding: '80px 30px',
  },
}));

const upcomingJobsColumns = [
  {
    name: 'Date',
    selector: (row: any) => row.scheduled_date,
    sortable: true,
  },
  {
    name: 'Client',
    selector: (row: any) => row.client_name,
    sortable: true,
  },
  {
    name: 'Address',
    selector: (row: any) => row.address,
    grow: 2,
    sortable: true,
  },
  {
    name: 'WO #',
    selector: (row: any) => row.order_no,
    sortable: true,
  },
  {
    name: 'CHLR #',
    selector: (row: any) => row.chlr_no,
    sortable: true,
  },
  {
    name: 'Logging Tech',
    selector: (row: any) => row.emp_id,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row: any) => row.status,
    sortable: true,
    cell: (row: Record<string, string>) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          color: row.status === 'IN PROGRESS' ? 'red' : 'green',
        }}
      >
        {row.status}
      </div>
    ),
  },
];

interface UpcomingJobsProps {
  role: string;
}

const UpcomingJobs: React.FunctionComponent<UpcomingJobsProps> = (props) => {
  const { role } = props;
  const classes = useStyles();
  const history = useHistory();

  const [chillerJobs, setChillerJobs] = useState<any>([]);
  const [filterValues, setFilterValues] = useState<any>({ user_id_eq: 0 });

  const fetchChillerJobs = async (user_id = '') => {
    try {
      const response = await axios.get<string, any>('/chiller_logs/chiller_jobs', {
        params: {
          'ransack[user_id_eq]': user_id,
          'ransack[start_date_gteq]': filterValues.start_date_gteq,
          'ransack[end_date_lteq]': filterValues.end_date_lteq,
        },
      });
      setChillerJobs(response?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch jobs whenever filterValues change.
  useEffect(() => {
    fetchChillerJobs(filterValues.user_id_eq);
  }, [filterValues]);

  return (
    <Box className={classes.box}>
      <Box>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4" component="h4">
              Jobs
            </Typography>
          </Grid>
        </Grid>
        <br />
        {role === 'admin' ? (
          <ChillerJobsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
        ) : (
          <ChillerTechnicianJobsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
        )}
        <br />
        <Card>
          <CardContent>
            <DataTable
              title=""
              columns={upcomingJobsColumns}
              data={chillerJobs}
              onRowClicked={(row: any) => history.push(`/chiller_logs/${row.id}/spreadsheet`)}
              striped
              dense
              highlightOnHover
              pointerOnHover
              pagination
              noHeader
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default UpcomingJobs;
