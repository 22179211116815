import React, { useState, useEffect } from 'react';
import Spreadsheet from 'react-spreadsheet';
import Grid from '@mui/material/Grid';
import { ChillerCircuit } from '../../model';

interface CondenserDetailsSubsectionProps {
  chillerCircuit: ChillerCircuit;
  index: number;
}

const CondenserDetailsSubsection: React.FunctionComponent<CondenserDetailsSubsectionProps> = (props) => {
  let { chillerCircuit, index } = props;

  let [evapSubSectionFormValues, setEvapSubSectionFormValues] = useState<ChillerCircuit>({});
  let [circuitEvaporatorData, setCircuitEvaporatorData] = useState([]);

  let readIndex = 0;
  if (chillerCircuit?.index !== undefined) {
    readIndex = chillerCircuit?.index + 1;
  }

  let columnLabels = [`Circuit ${readIndex}`, '', ''];

  useEffect(() => {}, [chillerCircuit]);

  useEffect(() => {}, [evapSubSectionFormValues]);

  const handleSpreadsheetChange = (updatedData) => {
    // setCircuitEvaporatorData(updatedData);
    // const updatedValues = fieldNames.reduce((acc, field, index) => {
    //   acc[field] = updatedData[index]?.[2]?.value || '0.0';
    //   return acc;
    // }, {});
    // let newValues = { ...evapSubSectionFormValues, ...updatedValues };
    // setEvapSubSectionFormValues(newValues);
    // const allStoredData = JSON.parse(localStorage.getItem('chillerCircuitSavedValues') || '{}');
    // allStoredData[chillerCircuit.id] = newValues;
    // localStorage.setItem('chillerCircuitSavedValues', JSON.stringify(allStoredData));
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ minWidth: 250 }}>
        <Spreadsheet
          data={chillerCircuit?.historical_data?.condenser_details}
          hideRowIndicators
          columnLabels={[`Circuit ${index + 1}`, '']}
          className="hide-labels circuit-table historical-data"
        />
        <Spreadsheet
          data={chillerCircuit?.condenser_details}
          hideRowIndicators
          columnLabels={['']}
          onChange={handleSpreadsheetChange}
          className="hide-labels circuit-table values-data"
        />
      </Grid>
    </Grid>
  );
};

export default CondenserDetailsSubsection;
