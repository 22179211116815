import React from 'react';
import { Grid, Card, CardContent, Typography, Switch, TextField, Box, IconButton } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SearchIcon from '@mui/icons-material/Search';
import { AuthenticationContext } from './auth/contexts/AuthenticationContext';

const IntegrationsPage = () => {
  const { user } = React.useContext(AuthenticationContext);

  const hasSalesEstimateModule = user?.modules?.some((module) => module.value === 'sales_estimate');
  const hasChillerLogModule = user?.modules?.some((module) => module.value === 'chiller_logs');

  const integrations = [
    {
      name: 'Sales Estimates',
      description: 'Create and track project sales estimates and replacement equipment for rooftop unit projects.',
      connected: hasSalesEstimateModule,
      icon: <MonetizationOnIcon />,
      onClick: hasSalesEstimateModule ? () => (window.location.href = '/rooftop_automation/recent') : () => null,
    },
    {
      name: 'Chiller Logs',
      description: 'Streamline the process of collecting, approving, and sharing HVAC unit condition logs',
      connected: hasChillerLogModule,
      icon: <FactCheckIcon />,
      onClick: hasChillerLogModule ? () => (window.location.href = '/chiller_logs') : () => null,
    },
    {
      name: 'Warehouse',
      description: 'Streamline inventory and warehouse management with real-time data tracking.',
      connected: false,
      icon: <WarehouseIcon />,
    },
    {
      name: 'Project Management',
      description: 'Organize and oversee Tudi projects from start to finish with task tracking tools.',
      connected: false,
      icon: <AccountTreeIcon />,
    },
  ];

  return (
    <Box padding={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold">
          Tudi Integrations
        </Typography>
        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            placeholder="Search integration..."
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
      <Grid container spacing={4}>
        {integrations.map((integration, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            style={integration.connected ? { cursor: 'pointer' } : {}}
            onClick={integration.onClick}
          >
            <Card variant="outlined">
              <CardContent style={integration.connected ? {} : { opacity: 0.6 }}>
                <Box display="flex" alignItems="center" mb={2}>
                  {integration.icon}
                  <Typography variant="h6" fontWeight="bold" style={{ marginLeft: 10 }}>
                    {integration.name}
                  </Typography>
                  <Box marginLeft="auto">
                    <Switch checked={integration.connected} />
                  </Box>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {integration.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default IntegrationsPage;
