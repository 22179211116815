import React, { useState, useEffect } from 'react';
import Spreadsheet from 'react-spreadsheet';
import Grid from '@mui/material/Grid';
import { ChillerLog } from '../../model';

interface TraneManufacturerSectionProps {
  chillerLog: ChillerLog;
}

const rowLabels = ['Submit Date', 'Oil Feed External Bearing', 'Oil Feed Internal Bearing', 'Winding Temp'];

const TraneManufacturerSection: React.FunctionComponent<TraneManufacturerSectionProps> = ({ chillerLog }) => {
  let [airCooledSectionFormValues, setAirCooledSectionFormValues] = useState<ChillerLog>({});

  useEffect(() => {
    if (!chillerLog?.id) return;

    const storedData = localStorage.getItem('chillerLogHeaderValues');
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};

    if (!parsedStoredData[chillerLog.id]) {
      parsedStoredData[chillerLog.id] = chillerLog?.trane_manufacturer_details;
      localStorage.setItem('chillerLogHeaderValues', JSON.stringify(parsedStoredData));
    }
  }, [chillerLog]);

  const handleSpreadsheetChange = (updatedData) => {
    setAirCooledSectionFormValues(updatedData);
    const allStoredData = JSON.parse(localStorage.getItem('chillerLogHeaderValues') || '{}');
    allStoredData[chillerLog.id] = updatedData;
    localStorage.setItem('chillerLogHeaderValues', JSON.stringify(allStoredData));
  };

  return (
    chillerLog && (
      <Grid>
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Additional Details</h3>
        <Spreadsheet
          data={chillerLog?.historical_data?.trane_manufacturer_details}
          rowLabels={rowLabels}
          columnLabels={['System', '']}
          onChange={handleSpreadsheetChange}
          className="hide-labels no-circuit-table fixed-row-labels historical-data"
        />
        <Spreadsheet
          data={chillerLog?.trane_manufacturer_details}
          hideRowIndicators
          columnLabels={['']}
          onChange={handleSpreadsheetChange}
          className="hide-labels no-circuit-table values-data"
        />
      </Grid>
    )
  );
};

export default TraneManufacturerSection;
