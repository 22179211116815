import React from 'react';
import { Grid, Button } from '@mui/material';
import InformationField from '../../../modules/form/components/InformationField';

interface ChillerLogHeaderProps {
  chillerJob?: any;
}

const ChillerLogHeader: React.FC<ChillerLogHeaderProps> = (props) => {
  const { chillerJob } = props;

  return (
    <Grid container style={{ marginLeft: -30, marginBottom: 10 }}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={4}>
          <Grid item>
            <InformationField label="Schedule Date" value={chillerJob?.scheduled_date} />
          </Grid>
          <Grid item>
            <InformationField label="WO #" value={chillerJob?.order_no} />
          </Grid>
          <Grid item>
            <InformationField label="Customer Name" value="Demo Client" />
          </Grid>
          <Grid item>
            <InformationField label="CHLR #" value={chillerJob?.chlr_no} />
          </Grid>
          <Grid item>
            <InformationField label="Model #" value={chillerJob?.model_no} />
          </Grid>
          <Grid item>
            <InformationField label="Serial #" value={chillerJob?.serial_no} />
          </Grid>
          <Grid item>
            <InformationField label="REFG" value={chillerJob?.refg} />
          </Grid>
          <Grid item>
            <InformationField label="Logging Tech" value={chillerJob?.emp_id} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChillerLogHeader;
