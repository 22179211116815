import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axios from '../../../modules/utils/axios.utils';
import { User } from '../model';

interface AuthenticationContextInterface {
  user: User;
  setUser?: Dispatch<SetStateAction<User>>;
  login?: (email: string, password: string, onSuccess: () => void) => Promise<void>;
  logout?: () => Promise<void>;
  currentUserHasRole?: (role: string) => boolean;
  loginFormError?: string;
  setLoginError?: Dispatch<SetStateAction<string>>;
}

interface AuthenticationContextProviderProps {
  children: React.ReactNode;
}

const AuthenticationContext = React.createContext<AuthenticationContextInterface>({ user: {} });

const AuthenticationContextConsumer = AuthenticationContext.Consumer;
const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> = ({ children }) => {
  const history = useHistory();
  const { accounts } = useMsal();

  const [user, setUser] = useState<any>();
  const [loginFormError, setLoginError] = useState<string>(undefined);

  const login = async (email: string, password: string, onSuccess: () => void) => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('token');
      setUser({});
      window.location.href = '/login';
    } catch {
      setUser({});
    }
  };

  const currentUserHasRole = (role: string) => {
    if (user?.roles?.find((user_role: string) => user_role === role)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        console.log('User account details:');
        console.log(accounts);
        const result = await axios.get<any, any>(`/sessions?username=${accounts[0]?.username}`);
        setUser(result?.data?.user);
      } catch (error) {
        setUser({});
      }
    }

    if (user === undefined) {
      fetchUser();
    }
  }, [user]);

  return (
    <AuthenticationContext.Provider
      value={{ user, setUser, login, logout, currentUserHasRole, loginFormError, setLoginError }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
export { AuthenticationContextProvider, AuthenticationContextConsumer, AuthenticationContext };
