import React, { useState, useEffect } from 'react';
import Spreadsheet from 'react-spreadsheet';
import { Button, Card, CardContent, Typography, Grid, TextField, CircularProgress, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import TextInput from '../../../modules/form/components/TextInput';
import AirCooledUnitSection from './AirCooledUnitSection';
import EvaporatorDetailsSection from './EvaporatorDetailsSection';
import CondenserDetailsSection from './CondenserDetailsSection';
import CompressorDetailsSection from './CompressorDetailsSection';
import AdditionalDetailsSection from './AdditionalDetailsSection';
import TraneManufacturerDetailsSection from './TraneManufacturerDetailsSection';
import YorkManufacturerDetailsSection from './YorkManufacturerDetailsSection';
import { AuthenticationContext } from '../../../homeModules/auth/contexts/AuthenticationContext';

import axios from '../../../modules/utils/axios.utils';

interface ChillerFormSpreadsheetProps {
  chillerJob?: any;
}

const ChillerFormSpreadsheet: React.FC<ChillerFormSpreadsheetProps> = (props) => {
  const { chillerJob } = props;
  const history = useHistory();

  const { user } = React.useContext(AuthenticationContext);

  const isTechnicianUser = user?.modules?.some(
    (module) => module.value === 'chiller_logs' && parseInt(module.tier) <= 2,
  );
  const isAdminUser = user?.modules?.some((module) => module.value === 'chiller_logs' && parseInt(module.tier) > 2);

  const [commentText, setCommentText] = React.useState('');
  const [images, setImages] = useState<string[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [currentChillerLog, setCurrentChillerLog] = React.useState<any>(null);
  const [validationStatus, setValidationStatus] = useState<'complete' | 'warning'>('complete');

  useEffect(() => {
    setCurrentChillerLog(chillerJob?.current_chiller_log);
  }, [chillerJob]);

  useEffect(() => {
    const checkErrorCells = () => {
      const errorCells = document.querySelectorAll('.error-cell');
      setValidationStatus(errorCells.length > 0 ? 'warning' : 'complete');
    };

    checkErrorCells();

    const observer = new MutationObserver(() => {
      checkErrorCells();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files).map((file) => URL.createObjectURL(file));
      setImages((prevImages) => [...prevImages, ...fileArray]);
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const getLocalStorageItem = (key: string, defaultValue = {}) =>
    JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue));

  const prepareChillerLogData = (currentChillerLog) => {
    const headerValues = getLocalStorageItem('chillerLogHeaderValues')[currentChillerLog?.id] || {};

    const circuitValues = (currentChillerLog?.circuits || []).map((circuit) => ({
      id: circuit.id,
      values: getLocalStorageItem('chillerCircuitSavedValues')[circuit.id] || [],
    }));

    return { header_values: headerValues, circuit_values: circuitValues };
  };

  const handleSubmit = async () => {
    if (!currentChillerLog) return;

    setSubmitting(true);

    try {
      const logData = prepareChillerLogData(currentChillerLog);

      await axios.put(`/chiller_logs/chiller_logs/${currentChillerLog.id}`, logData);
    } catch (error) {
      console.error('Error updating chiller log:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Grid container style={{ background: '', marginLeft: -30, width: 1400 }}>
      <Grid item xs={12}>
        <AirCooledUnitSection chillerLog={currentChillerLog} />
      </Grid>
      <Grid item xs={12}>
        <EvaporatorDetailsSection chillerLog={currentChillerLog} />
      </Grid>
      <Grid item xs={12}>
        <CondenserDetailsSection chillerLog={currentChillerLog} />
      </Grid>
      <Grid item xs={12}>
        <CompressorDetailsSection chillerLog={currentChillerLog} />
      </Grid>
      <Grid item xs={12}>
        <AdditionalDetailsSection chillerLog={currentChillerLog} />
      </Grid>
      {true && (
        <Grid item xs={12}>
          <TraneManufacturerDetailsSection chillerLog={currentChillerLog} />
        </Grid>
      )}
      {false && (
        <Grid item xs={12}>
          <YorkManufacturerDetailsSection chillerLog={currentChillerLog} />
        </Grid>
      )}
      <Grid item xs={12}>
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Comments</h3>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={commentText}
          valueChanged={(value) => setCommentText(value)}
          label={commentText.length > 0 ? 'Comment' : 'Enter your comments here...'}
          rows={5}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <h3 style={{ background: '#A21C37', color: '#fff', fontWeight: 700, padding: 5 }}>Photos</h3>
      </Grid>
      <Grid item xs={12} style={{ background: '#fff', padding: 5 }} className="no-print">
        {images.length === 0 && <p style={{ margin: '10px 0' }}>No photos have been uploaded for this job.</p>}
        {images.length > 0 && (
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            {images.map((src, index) => (
              <Grid item key={index} xs={4} sm={3} md={2} style={{ position: 'relative' }}>
                <img src={src} alt={`Uploaded ${index}`} style={{ width: '100%', height: 'auto', borderRadius: 5 }} />
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: 'absolute',
                    top: 5,
                    right: 0,
                    background: 'rgba(0, 0, 0, 0.6)',
                    color: 'white',
                  }}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        )}
        <input type="file" id="file-upload" multiple style={{ display: 'none' }} onChange={handleFileChange} />
        {isTechnicianUser && (
          <Button style={{ marginTop: 5 }} variant="contained" component="label" htmlFor="file-upload">
            Upload Photos/Files
          </Button>
        )}
      </Grid>
      <br />
      <Grid item xs={12} style={{ marginTop: 20 }} className="no-print">
        <Card
          style={{
            background: validationStatus === 'complete' ? '#2E7D32' : '#EF5350',
            color: 'white',
            padding: 10,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CardContent>
            <Typography variant="h6">
              {validationStatus === 'complete' ? '✅ Data Validations Complete' : '⚠️ Data Validations Warning'}
            </Typography>
            <Typography variant="body2">
              {validationStatus === 'complete'
                ? 'All data is correctly filled and within acceptable thresholds.'
                : 'Some data is missing or outside expected thresholds, please review the following errors:'}
            </Typography>
            {validationStatus !== 'complete' && (
              <ul>
                <li>74 fields are blank</li>
                {commentText.length === 0 && <li>Comments are blank</li>}
                {images.length === 0 && <li>No photos have been uploaded</li>}
                <li>Evaporator details, Circuit 1 has a field outside of threshold</li>
              </ul>
            )}
          </CardContent>
        </Card>
      </Grid>
      {isTechnicianUser && (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', alignItems: 'center', background: 'white', padding: 5 }}
          className="no-print"
        >
          <Button onClick={handleSubmit} variant="contained" disabled={submitting}>
            {submitting ? 'Submitting Log...' : 'Submit'}
          </Button>
          {submitting && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
        </Grid>
      )}
      {isAdminUser && (
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 20 }} className="no-print">
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={submitting || currentChillerLog?.status !== 'pending_approval'}
          >
            {submitting ? 'Approving Log...' : 'Approve'}
          </Button>
          {submitting && <CircularProgress size={24} style={{ marginLeft: 10 }} />}
        </Grid>
      )}
    </Grid>
  );
};

export default ChillerFormSpreadsheet;
