import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Stepper, Step, StepLabel, Button, Grid, TextField, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useReactToPrint } from 'react-to-print';
import ChillerFormSpreadsheet from '../components/ChillerFormSpreadsheet';
import ChillerLogHeader from '../components/ChillerLogHeader';
import PreviousLogDialog from '../components/PreviousLogDialog';
import { SnackBarContext } from '../../../modules/form/contexts/SnackBarContext';
import axios from '../../../modules/utils/axios.utils';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    padding: '20px',
  },
  stepContent: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const UpcomingJobDetail: React.FunctionComponent = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { showSuccessSnackBar } = useContext(SnackBarContext);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [chillerJob, setChillerJob] = useState<any>();
  const [circuitCount, setCircuitCount] = useState<number>(0);
  const handlePrint = useReactToPrint({ contentRef });

  const fetchChillerJob = async () => {
    try {
      const response = await axios.get<string, any>(`/chiller_logs/chiller_jobs/${id}`);
      const job = response?.data?.result;
      setChillerJob(job);
      setCircuitCount(job?.current_chiller_log?.circuit_count || 0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChillerJob();
  }, []);

  const saveFormProgress = () => {
    setTimeout(() => {
      showSuccessSnackBar('Form saved successfully.');
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      showSuccessSnackBar('Saving form progress...');
      saveFormProgress();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleCircuitCountChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCount = Number(event.target.value);
    setCircuitCount(newCount);
    try {
      const response = await axios.put<any, any>(`/chiller_logs/chiller_jobs/${id}`, {
        chiller_log: { circuit_count: newCount },
      });
      showSuccessSnackBar('Circuit count updated successfully.');
      setChillerJob(response?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box className={classes.root} style={{ marginLeft: -200 }}>
      <PreviousLogDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography
            style={{ marginBottom: 10, color: '#333', opacity: 0.6, cursor: 'pointer', marginLeft: -30 }}
            onClick={() => history.push('/chiller_logs')}
          >
            ← Return to Upcoming Jobs
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: -200 }}>
          <Button variant="contained" color="primary" onClick={handlePrint as any}>
            Print Log
          </Button>
        </Grid>
      </Grid>
      <ChillerLogHeader chillerJob={chillerJob} />
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ marginTop: 20 }}>
          <TextField
            select
            label="Circuit Count"
            value={circuitCount}
            onChange={handleCircuitCountChange}
            variant="outlined"
            fullWidth
            style={{ marginLeft: -30, marginTop: 20 }}
          >
            {[1, 2, 3, 4].map((count) => (
              <MenuItem key={count} value={count}>
                {count}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ marginTop: 20 }}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Previous Logs
            </Typography>
            <div>
              <strong>Prev 1:</strong> 07/02/24 &mdash;
              <Button style={{ marginLeft: 0 }} onClick={() => setDialogOpen(true)}>
                View Full Log
              </Button>
            </div>
            <div>
              <strong>Prev 2:</strong> 07/22/24 &mdash;
              <Button style={{ marginLeft: 0 }} onClick={() => setDialogOpen(true)}>
                View Full Log
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
      <div ref={contentRef} className="chiller-log-wrapper">
        <div className="display-only-for-print">
          <ChillerLogHeader chillerJob={chillerJob} />
        </div>
        {chillerJob ? <ChillerFormSpreadsheet chillerJob={chillerJob} /> : <p>Loading...</p>}
      </div>
    </Box>
  );
};

export default UpcomingJobDetail;
