import React, { useEffect, useState, useContext } from 'react';
import { Button, Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import TextInput from '../../form/components/TextInput';
import SelectInput from '../../form/components/SelectInput';
import { UsersContext } from '../contexts/UsersContext';
import { UserFormValues, User } from '../model';
import axios from '../../utils/axios.utils';

interface ModuleOption {
  label: string;
  value: string;
}

interface UserFormProps {
  user?: User;
  onSuccess?: () => void;
}

const UserForm: React.FunctionComponent<UserFormProps> = (props) => {
  const { user, onSuccess } = props;
  const [formValues, setFormValues] = useState<UserFormValues>();
  const [choicesFetched, setChoicesFetched] = useState(false);
  const [modules, setModules] = useState<ModuleOption[]>([]);

  const { updateUser, userLoading } = useContext(UsersContext);

  useEffect(() => {
    setFormValues({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      role_name: user.role_name,
      modules: user.modules || [],
    });
  }, [user]);

  useEffect(() => {
    axios.get<any>('/users/new').then((response) => {
      if (response?.data?.available_modules) {
        setModules(response.data.available_modules);
      }
    });
  }, []);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdateUser = () => {
    updateUser(user?.id || '', formValues, () => window.location.reload);
  };

  const handleRoleChange = (value: string) => {
    const permissionsByRole: Record<string, any> = {
      no_access: modules.map((m) => ({ value: m.value, tier: 0 })),
      platform_superadmin: modules.map((m) => ({ value: m.value, tier: 3 })),
      sales_estimate_default_user: modules.map((m) =>
        m.value === 'sales_estimate' ? { value: m.value, tier: 2 } : { value: m.value, tier: 0 },
      ),
      chiller_logs_default_user: modules.map((m) =>
        m.value === 'chiller_logs' ? { value: m.value, tier: 2 } : { value: m.value, tier: 0 },
      ),
      custom: formValues?.modules || [],
    };
    setFormValues((prev) => ({
      ...prev,
      modules: permissionsByRole[value],
      role_name: value,
    }));
  };

  const handlePermissionTierChange = (moduleValue: string, tier: number) => {
    const updatedModules = (formValues?.modules || []).map((mod) =>
      mod.value === moduleValue ? { ...mod, tier } : mod,
    );
    setFormValues((prev) => ({ ...prev, modules: updatedModules }));
  };

  const tierOptions = [
    { label: 'No Access', value: 0 },
    { label: 'Read-Only', value: 1 },
    { label: 'Read/Write', value: 2 },
    { label: 'Admin', value: 3 },
  ];

  return (
    !userLoading && (
      <>
        <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInput
                type="text"
                label="First Name"
                shrinkLabel
                value={formValues?.first_name}
                valueChanged={(value) => handleFormValueChange('first_name', value.trim())}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                type="text"
                label="Last Name"
                shrinkLabel
                value={formValues?.last_name}
                valueChanged={(value) => handleFormValueChange('last_name', value.trim())}
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                type="text"
                label="Email"
                shrinkLabel
                disabled
                value={formValues?.email}
                valueChanged={(value) => handleFormValueChange('email', value.trim())}
              />
            </Grid>
          </Grid>
        </form>

        <Typography variant="h6" gutterBottom>
          Manage Module Access
        </Typography>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <SelectInput
            type="text"
            label="Select A Role"
            value={formValues?.role_name}
            choices={[
              { label: 'No Access', value: 'no_access' },
              { label: 'Platform Superadmin', value: 'platform_superadmin' },
              { label: 'Sales Estimate Default User', value: 'sales_estimate_default_user' },
              { label: 'Chiller Logs Default User', value: 'chiller_logs_default_user' },
              { label: 'Custom', value: 'custom' },
            ]}
            valueChanged={(value) => handleRoleChange(value)}
          />
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Module</strong>
              </TableCell>
              {tierOptions.map((tier) => (
                <TableCell key={tier.value}>
                  <strong>{tier.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {modules.map((mod) => {
              const foundModule = formValues?.modules?.find((m) => {
                return m.value === mod.value;
              });
              const currentTier = foundModule?.tier ?? 0;

              return (
                <TableRow key={mod.value}>
                  <TableCell>{mod.label}</TableCell>
                  {tierOptions.map((tier) => {
                    const isChecked = currentTier === tier.value;
                    return (
                      <TableCell key={`${mod.value}-${tier.value}`}>
                        <Checkbox
                          checked={isChecked}
                          onChange={() => handlePermissionTierChange(mod.value, tier.value)}
                          disabled={formValues?.role_name !== 'custom'}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Button variant="contained" color="primary" onClick={handleUpdateUser} style={{ marginTop: '20px' }}>
          Update User
        </Button>
      </>
    )
  );
};

export default UserForm;
