import React, { useState, useEffect } from 'react';
import Spreadsheet from 'react-spreadsheet';
import Grid from '@mui/material/Grid';
import { ChillerCircuit } from '../../model';

interface EvaporatorDetailsSubsectionProps {
  chillerCircuit: ChillerCircuit;
}

const EvaporatorDetailsSubsection: React.FunctionComponent<EvaporatorDetailsSubsectionProps> = (props) => {
  let { chillerCircuit } = props;

  let [evapSubSectionFormValues, setEvapSubSectionFormValues] = useState<any>(chillerCircuit?.evaporator_details);

  useEffect(() => {
    const storedData = localStorage.getItem('chillerCircuitSavedValues');
    const parsedStoredData = storedData ? JSON.parse(storedData) : {};

    if (!parsedStoredData[chillerCircuit.id]) {
      parsedStoredData[chillerCircuit.id] = {};
    }

    if (!parsedStoredData[chillerCircuit.id].evaporator_details) {
      parsedStoredData[chillerCircuit.id].evaporator_details = chillerCircuit?.evaporator_details;
      localStorage.setItem('chillerCircuitSavedValues', JSON.stringify(parsedStoredData));
    }
  }, [chillerCircuit]);

  const handleSpreadsheetChange = (updatedData) => {
    setEvapSubSectionFormValues(updatedData);
    const allStoredData = JSON.parse(localStorage.getItem('chillerCircuitSavedValues') || '{}');
    allStoredData[chillerCircuit.id].evaporator_details = updatedData;
    localStorage.setItem('chillerCircuitSavedValues', JSON.stringify(allStoredData));
  };

  const highlightData = (data) =>
    data.map((row) =>
      row.map((cell) => ({
        ...cell,
        className: cell.value > 100 ? 'error-cell' : '',
      })),
    );

  return (
    <Grid container>
      <Grid item xs={12} sx={{ minWidth: 250 }}>
        <Spreadsheet
          data={chillerCircuit?.historical_data?.evaporator_details}
          hideRowIndicators
          columnLabels={[`Circuit ${chillerCircuit?.index + 1}`, '']}
          className="hide-labels circuit-table historical-data"
        />
        <Spreadsheet
          data={highlightData(evapSubSectionFormValues)}
          hideRowIndicators
          columnLabels={['']}
          onChange={handleSpreadsheetChange}
          className="hide-labels circuit-table values-data"
        />
      </Grid>
    </Grid>
  );
};

export default EvaporatorDetailsSubsection;
